<template>
  <div class="Consult">
    <p>合作咨询</p>
    <p>{{ consule.name }}</p>
    <p>联系地址：{{ consule.site }}</p>
    <p>联系电话：{{ consule.tel }}</p>
    <p>联系邮箱：{{ consule.email }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consule: {
        name: "福建省产教融合综合服务平台",
        site: "福建省泉州市小店XX区南中环桥东创新街智创城省级双创中心B座办公室（一）",
        tel: "+86 0351-2559898",
        email: "sxscjrhcjh@163.com",
      },
    };
  },
};
</script>
<style scoped>
.Consult > p {
  font-size: 13px;
  margin: 15px 0;
}
.Consult > p:nth-of-type(1) {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}
.Consult > p:nth-of-type(2) {
  font-weight: bold;
}
</style>