import request from "../../../common/utils/request";

// 获取双师库信息
export function getTeachersInfo(params) {
  return request({
    url: "/itf/common/cjrh/double/getDouble.json",
    method: "get",
    params:params,
  });
}
// 根据Id查询双师库详细信息
export function getTeachersById(params) {
  return request({
    url: "/itf/common/cjrh/double/getDoubleById.json",
    method: "get",
    params:params,
  });
}